import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"

import MapPOS from "../components/posMap"
import { Bar, Item } from "../components/ui/tabs"

export default function howToBook({ location, data }) {
  return (
    <>
      <SEO title="Merchants" />
      <Page>
        <div className="container">
          <h1>About Us</h1>
          <p className="header-text">
            TravelbyBit is an online travel booking platform built for the
            blockchain generation.
            <br />
            We also help travellers and tourists experience the world using
            digital currencies like Bitcoin. We enable users to spend their
            digital currency at a growing network of retail, dining, and
            activity service providers
          </p>
        </div>
        <Bar>
          <Container>
            <Link to="/how-to-book">
              <Item selected={location.pathname === "/how-to-book"}>
                <h3>HOW TO BOOK</h3>
              </Item>
            </Link>
            <Link to="/merchants">
              <Item selected={location.pathname === "/merchants"}>
                <h3>MERCHANTS</h3>
              </Item>
            </Link>
          </Container>
        </Bar>
        <div className="container">
          <div className="content">
            <h2>FIND A TRAVELBYBIT MERCHANT NEAR YOU</h2>
            {data && <MapPOS merchants={data.allGoogleSheet1Sheet.edges} />}
          </div>
        </div>
      </Page>
    </>
  )
}

export const query = graphql`
  query MerchantQuery {
    allGoogleSheet1Sheet {
      edges {
        node {
          merchant
          latitude
          longitude
        }
      }
    }
  }
`

const Page = styled.div`
  min-height: calc(100vh - 65px);
  color: #333;

  @media only screen and (max-width: 1169px) {
    padding: 0 1rem;
  }

  .container {
    max-width: 1170px;
    margin: 0 auto;
  }

  .header-text {
    margin: 0.5rem 0 2rem 0;
    line-height: 1.625;

    br {
      margin: 0.5rem 0;
    }
  }

  .content {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 0px 15px 5px rgba(0, 0, 0, 0.07);
    padding: 2rem 2rem;
    margin: 2rem 0 3rem 0;

    h2 {
      text-align: center;
    }

    .line {
      height: 1px;
      width: 100%;
      background: rgba(3, 3, 3, 0.2);
      margin-bottom: 2rem;
    }
  }
`

const Container = styled.div`
  display: flex;
  margin: 0 auto;
  width: 1170px;
`
