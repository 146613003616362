import React, { useState } from "react"
import styled from "styled-components"
import { Map, Marker, GoogleApiWrapper, InfoWindow } from "google-maps-react"
import { Link } from "gatsby"

import theme from "../theme"

const regions = {
  ACT: {
    pos: { lat: -35.444468, lng: 148.976107 },
    zoom: 6,
  },
  NSW: {
    pos: { lat: -32.710833, lng: 146.43792 },
    zoom: 6,
  },
  NT: {
    pos: { lat: -19.416711, lng: 133.304716 },
    zoom: 6,
  },
  QLD: {
    pos: { lat: -22.994819, lng: 144.519582 },
    zoom: 6,
  },
  SA: {
    pos: { lat: -29.860254, lng: 135.191501 },
    zoom: 6,
  },
  TAS: {
    pos: { lat: -41.949953, lng: 146.664893 },
    zoom: 8,
  },
  VIC: {
    pos: { lat: -36.962623, lng: 144.262114 },
    zoom: 6,
  },
  WA: {
    pos: { lat: -26.459191, lng: 122.32647 },
    zoom: 6,
  },
}

export default function PosMap(props) {
  const [mapSelected, setMapSelected] = useState("QLD")
  const [showInfoWindow, setShowInfoWindow] = useState(false)
  const [activeMarker, setActiveMarker] = useState({})
  const [selectedPlace, setSelectedPlace] = useState({})

  const handleMapChange = (item) => {
    if (mapSelected !== item) setMapSelected(item)
  }

  const onMapClicked = () => {
    if (showInfoWindow) {
      setShowInfoWindow(false)
      setActiveMarker(null)
    }
  }

  const onMarkerClick = (props, marker, e) => {
    setActiveMarker(marker)
    setShowInfoWindow(true)
    setSelectedPlace(props)
  }

  const { merchants } = props
  const locations = merchants.map((merchant) => {
    return {
      merchant: merchant.node.merchant,
      lat: merchant.node.latitude,
      lng: merchant.node.longitude,
    }
  })

  const renderNavBar = () => {
    return (
      <>
        <Navbar>
          {Object.entries(regions).map(([key, val]) => (
            <li
              onClick={() => handleMapChange(key)}
              key={key}
              className={mapSelected === key ? "active" : ""}
            >
              {key}
            </li>
          ))}
        </Navbar>
        <div className="line" />
      </>
    )
  }

  return (
    <MapContainer>
      {renderNavBar()}
      <Map
        google={props.google}
        onClick={onMapClicked}
        initialCenter={regions[mapSelected].pos}
        center={regions[mapSelected].pos}
        zoom={regions[mapSelected].zoom}
        style={{ position: "inherit", height: "83%" }}
      >
        {locations.map((merchant, i) => {
          return (
            <Marker
              onClick={onMarkerClick}
              position={{ lat: merchant.lng, lng: merchant.lat }}
              name={merchant.merchant}
              key={i}
              title={merchant.merchant}
            />
          )
        })}
        <InfoWindow marker={activeMarker} visible={showInfoWindow}>
          <div>
            <p>{selectedPlace.name}</p>
          </div>
        </InfoWindow>
      </Map>
    </MapContainer>
  )
}

PosMap = GoogleApiWrapper({
  apiKey: process.env.GOOGLE_API_KEY,
})(PosMap)

const Navbar = styled.ul`
  display: flex;
  list-style: none;
  margin-top: 2rem;
  margin-bottom: 0.5rem;

  li {
    flex-grow: 1;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: ${theme.fontColourLight};

    &:hover {
      cursor: pointer;
    }
  }

  .active {
    background: #d2d4d5;
    border-radius: 5px;
  }

  @media only screen and (max-width: 585px) {
    li {
      padding: 0;
    }
  }
`

const MapContainer = styled.div`
  height: 70vh;
  position: relative;
`
